import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {AsperaLoginPage, Link, tracking, Button, InlineNotification} from '@aspera-ui/starter';
import '@aspera-ui/starter/styles/aspera-core-global.scss';
import '@aspera-ui/starter/styles/aspera-global.scss';
import './styles.scss';
class LoginPage extends React.Component {
  state = {
    loading: true,
    error: false,
  };

  basicLoginData = {};
  basicHeaderBrandingOptions = {};

  getButton = (button) => {
    return (
      <div className="button-wrapper" key={button.id}>
        <Button kind={button.kind} href={button.link} onClick={() => console.log(`${button.button_name} clicked`)}>
          {button.button_name}
        </Button>
        {button.link_div === true ? (
          <div className="auth-provider-instructions">
            Do you already have an account?&nbsp;
            <Link href={button.instruction_details?.link} id={button.instruction_details?.id}>Log in</Link>
            &nbsp;to continue.
          </div>
        ) : (
          <div className="auth-provider-instructions">{button.instruction_details?.name}</div>
        )}
      </div>
    );
  }

  get useWorkspaceSetting() {
    return this.props.loginData.workspaceBoolean && this.props.loginData.workspaceLoginCustOverrideBoolean;
  }

  componentDidMount() {
    tracking.currentTheme = tracking.systemTheme;

    this.basicLoginData = {
      layout: this.useWorkspaceSetting ? this.props.loginData.workspaceLoginLayout : this.props.loginData.orgLoginlayout,
      announcementOneTitle: this.useWorkspaceSetting ? this.props.loginData.workspaceAnnouncementOneTitle : this.props.loginData.orgAnnouncementOneTitle,
      announcementOneMessage: this.useWorkspaceSetting ? this.props.loginData.workspaceAnnouncementOneMessage : this.props.loginData.orgAnnouncementOneMessage,
      announcementTwoTitle: this.useWorkspaceSetting ? this.props.loginData.workspaceAnnouncementTwoTitle : this.props.loginData.orgAnnouncementTwoTitle,
      announcementTwoMessage: this.useWorkspaceSetting ? this.props.loginData.workspaceAnnouncementTwoMessage : this.props.loginData.orgAnnouncementTwoMessage,
      loginPageBackgroundPosition: this.useWorkspaceSetting ? this.props.loginData.workspaceLoginPageBackgroundPosition : this.props.loginData.organizationLoginPageBackgroundPosition,
      loginPageLogo: {initialData: this.useWorkspaceSetting ? this.props.headerData.workspaceLoginImageUrl : this.props.headerData.orgLoginImageUrl},
      loginPageBackground: {initialData: this.useWorkspaceSetting ? this.props.headerData.workspaceLoginBackgroundImageUrl : this.props.headerData.orgLoginBackgroundImageUrl},
    };

    this.basicHeaderBrandingOptions = {
      displayName: 'Aspera on Cloud',
      imageUrl:  this.props.headerData.orgAppLogoImageUrl,
      customText: this.props.headerData.organizationAppLogoText
    };

    if (this.props.headerData.customTheme) {
      tracking.currentCustomTheme = this.props.headerData.customTheme;
    };

    this.setState({
      loading: false,
    });
  }

  render() {
    const {loading, error} = this.state;
    const showBackdoorPageButton = this.props.loginData.showBackdoorPageButton
    const primarybuttonData = this.props.loginData.primaryAuthProvider
    const secondarybuttonData = this.props.loginData.secondaryAuthProviders || []
    const notificationError = this.props.loginData.inlineNotificationError

    return (
      <Router>
        <AsperaLoginPage
          headerData={this.basicHeaderBrandingOptions}
          productName="Aspera on Cloud"
          pageHeader={(this.useWorkspaceSetting ? this.props.headerData.workspaceName : this.props.headerData.orgName) || this.props.headerData.orgName}
          loginData={this.basicLoginData}
          externalLoading={loading}
          externalError={error}
          footerString="IBM Aspera on Cloud. 2016, 2023 © Copyright IBM Corporation. All rights reserved."
        >
          {notificationError && (<InlineNotification role="alert" className='inline-notification' kind="error" title={notificationError.title} subtitle={<div dangerouslySetInnerHTML={{__html: notificationError.subtitle}} />}hideCloseButton={true}/>)}
          {showBackdoorPageButton && this.getButton(showBackdoorPageButton)}
          {primarybuttonData && this.getButton(primarybuttonData)}
          {secondarybuttonData.map(button => this.getButton(button))}
        </AsperaLoginPage>
      </Router>
    );
  }
}

export default LoginPage;
